/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { AssetType, AssetTypeFromJSON, AssetTypeFromJSONTyped, AssetTypeToJSON } from "./AssetType";
import {
  ChecklistSection,
  ChecklistSectionFromJSON,
  ChecklistSectionFromJSONTyped,
  ChecklistSectionToJSON,
} from "./ChecklistSection";
import { Phase, PhaseFromJSON, PhaseFromJSONTyped, PhaseToJSON } from "./Phase";
import { Project, ProjectFromJSON, ProjectFromJSONTyped, ProjectToJSON } from "./Project";
import { User, UserFromJSON, UserFromJSONTyped, UserToJSON } from "./User";

/**
 *
 * @export
 * @interface Checklist
 */
export interface Checklist {
  /**
   *
   * @type {number}
   * @memberof Checklist
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Checklist
   */
  name?: string | null;
  /**
   *
   * @type {number}
   * @memberof Checklist
   */
  projectId?: number;
  /**
   *
   * @type {Project}
   * @memberof Checklist
   */
  project?: Project;
  /**
   *
   * @type {User}
   * @memberof Checklist
   */
  createdBy?: User;
  /**
   *
   * @type {Phase}
   * @memberof Checklist
   */
  phase?: Phase;
  /**
   *
   * @type {boolean}
   * @memberof Checklist
   */
  isCheckedOut?: boolean;
  /**
   *
   * @type {Date}
   * @memberof Checklist
   */
  lastUpdatedAt?: Date | null;
  /**
   *
   * @type {Array<ChecklistSection>}
   * @memberof Checklist
   */
  sections?: Array<ChecklistSection> | null;
  /**
   *
   * @type {Array<AssetType>}
   * @memberof Checklist
   */
  assetTypes?: Array<AssetType> | null;
}

export function ChecklistFromJSON(json: any): Checklist {
  return ChecklistFromJSONTyped(json, false);
}

export function ChecklistFromJSONTyped(json: any, ignoreDiscriminator: boolean): Checklist {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    name: !exists(json, "name") ? undefined : json["name"],
    projectId: !exists(json, "projectId") ? undefined : json["projectId"],
    project: !exists(json, "project") ? undefined : ProjectFromJSON(json["project"]),
    createdBy: !exists(json, "createdBy") ? undefined : UserFromJSON(json["createdBy"]),
    phase: !exists(json, "phase") ? undefined : PhaseFromJSON(json["phase"]),
    isCheckedOut: !exists(json, "isCheckedOut") ? undefined : json["isCheckedOut"],
    lastUpdatedAt: !exists(json, "lastUpdatedAt")
      ? undefined
      : json["lastUpdatedAt"] === null
      ? null
      : new Date(json["lastUpdatedAt"]),
    sections: !exists(json, "sections")
      ? undefined
      : json["sections"] === null
      ? null
      : (json["sections"] as Array<any>).map(ChecklistSectionFromJSON),
    assetTypes: !exists(json, "assetTypes")
      ? undefined
      : json["assetTypes"] === null
      ? null
      : (json["assetTypes"] as Array<any>).map(AssetTypeFromJSON),
  };
}

export function ChecklistToJSON(value?: Checklist | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    projectId: value.projectId,
    project: ProjectToJSON(value.project),
    createdBy: UserToJSON(value.createdBy),
    phase: PhaseToJSON(value.phase),
    isCheckedOut: value.isCheckedOut,
    lastUpdatedAt:
      value.lastUpdatedAt === undefined
        ? undefined
        : value.lastUpdatedAt === null
        ? null
        : value.lastUpdatedAt.toISOString(),
    sections:
      value.sections === undefined
        ? undefined
        : value.sections === null
        ? null
        : (value.sections as Array<any>).map(ChecklistSectionToJSON),
    assetTypes:
      value.assetTypes === undefined
        ? undefined
        : value.assetTypes === null
        ? null
        : (value.assetTypes as Array<any>).map(AssetTypeToJSON),
  };
}
